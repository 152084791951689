export const ImpactsData = [
  {
    title: "Purpose explorers",
    total: "200+",
  },
  {
    title: "Dream realizers",
    total: "25+",
  },
  {
    title: "Wholehearted mentors",
    total: "14",
  },
  {
    title: "Happy parents",
    total: "10+",
  },
];
