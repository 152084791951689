export const OurMissionData = [
  {
    number: "02",
    description:
      "To revolutionize the learning models in context with contemporary boards and institutional learning models through mandating life skills with self thinking capabilities as the core basic disciplinary.",
  },
  {
    number: "03",
    description:
      "To produce more & more excellencies generationally, with impact and outcome focused behavior pattern and limitless thinking.",
  },
];
